<!--  -->
<template>
  <div
    class="main_box"
    v-loading="loading"
    element-loading-text="数据量过大，加载中..."
  >
    <div class="nav_menu">
      <div class="menu_left">
        <el-input placeholder="请输入站点名称" v-model="siteName"></el-input>
        <el-date-picker
          :editable="false"
          style="margin-left: -10px"
          v-model="values"
          type="datetimerange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          prefix-icon="el-icon-time"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <el-button type="primary" @click="searchList">查询</el-button>
        <el-button type="info" @click="resetList">重置</el-button>
      </div>
      <!-- http://storage.xuetangx.com/public_assets/xuetangx/PDF/PlayerAPI_v1.0.6.pdf -->
      <!-- <iframe src="http://storage.xuetangx.com/public_assets/xuetangx/PDF/PlayerAPI_v1.0.6.pdf" width="100%" height="900px" >
        </iframe> -->
        <!-- <embed src="/阳信计量单元成果.pdf" type="application/pdf" width="100%" height="800"> -->
      <div class="menu_right flex flex-a-c">
         
        <div v-if="show" class="checkedAll flex flex-a-c" @click="checkedAll">
          <img
            v-if="!isCheckedAll"
            src="../../assets/images/Capture/unchecked.png"
            alt=""
          />
          <img v-else src="../../assets/images/Capture/checked.png" alt="" />
          <span>全选</span>
        </div>
        <div v-if="show" class="checkedAll flex flex-a-c" @click="exportExcel">
          <img src="../../assets/images/Capture/export.png" alt="" />
          <span style="color: #22ac38">导出</span>
        </div>
        <div v-if="show" class="checkedAll flex flex-a-c" @click="deleteFruits">
          <img src="../../assets/images/Capture/delete.png" alt="" />
          <span style="color: #d81e06">删除</span>
        </div>
        <el-button class="edit" @click="openShow" style="background:#0075ff"
          >批量管理</el-button
        >
      </div>
    </div>
    <!-- 视频 -->
    <div class="capture_main">
      <div class="capture_main_box">
        <div
          class="flex flex-j-c flex-a-c"
          style="width:100%;height:90%"
          v-if="CaptureList.length == '0'"
        >
          <img
            src="../../assets/images/Capture/zanwu.png"
            style="width:calc(330/1920*100vw); height:calc(329/1080*100vh)"
            st
            alt=""
          />
        </div>
        <div
          class="capture_box"
          v-for="(item, i) in CaptureList"
          :key="i"
          v-else
        >
          <el-image
            style="height: 100%;"
            :src="item.alarmPicUrl"
            :preview-src-list="CaptureListImg"
          >
          </el-image>
          <!-- <viewer>
            <img :src="item.alarmPicUrl" alt="" @error.once="moveErrorImg" />
          </viewer> -->
          <input
            v-if="show"
            type="checkbox"
            :checked="checkList.indexOf(item.id) >= 0"
            class="input-box"
            @click="checkedOne(item.id)"
          />
        </div>
      </div>

      <div>
        <el-pagination
          :hide-on-single-page="true"
          background
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
          @prev-click="nextpage"
          @next-click="nextpage"
          @current-change="nextpage"
          :current-page="page"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { exportList } from "../../api/Capture";
export default {
  components: {},
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      loading: false,
      siteName: "",
      CaptureList: [],
      CaptureListImg: [],
      values: [],
      page: 1,
      size: 6,
      total: 0,
      pageSize: 0,
      ids: [],
      show: false,
      checkList: [], // 初始化选中状态
      isCheckedAll: false, // 初始化全选状态
      startTime: "", // 开始时间
      endTime: "" // 结束时间
    };
  },
  computed: {},
  watch: {},
  // methods: {
  //   // 选中的方法
  //   checkedOne(id) {
  //     let idIndex = this.checkList.indexOf(id);
  //     console.log(idIndex);
  //     if (idIndex >= 0) {
  //       this.isCheckedAll = false;
  //       // 如果已经包含了该id, 则去除(单选按钮由选中变为非选中状态)
  //       this.checkList.splice(idIndex, 1);
  //     } else {
  //       if (this.checkList.length == 6) {
  //         this.isCheckedAll = true;
  //         this.checkList.push(id);
  //       } else {
  //         this.isCheckedAll = false;
  //         this.checkList.push(id);
  //         console.log(this.checkList);
  //       }
  //     }
  //   },
  //   moveErrorImg(event) {
  //     // event.currentTarget.src = require("../../assets/images/Capture/checked.png");
  //     // event.currentTarget.style.width = "auto";
  //     // return true;
  //   },
  //   // 全选的方法
  //   checkedAll() {
  //     this.isCheckedAll = !this.isCheckedAll;
  //     if (this.isCheckedAll) {
  //       // 全选时
  //       this.checkList = [];
  //       this.CaptureList.forEach(function(item) {
  //         this.checkList.push(item.id);
  //       }, this);
  //     } else {
  //       this.checkList = [];
  //     }
  //   },
  //   // 删除的方法
  //   deleteFruits() {
  //     if (this.checkList.length !== 0) {
  //       this.$confirm("此操作将永久删除该图片, 是否继续?", "提示", {
  //         confirmButtonText: "确定",
  //         cancelButtonText: "取消",
  //         type: "warning"
  //       })
  //         .then(() => {
  //           // 删除接口
  //           this.$post(
  //             `/videoAlarmRecord/deleteByIds?ids=${this.checkList}`
  //           ).then(res => {
  //             if (res.code == 200) {
  //               this.$message({
  //                 type: "success",
  //                 message: res.message
  //               });
  //               this.size = 6;
  //               if (this.total % 6 == 1) {
  //                 this.page--;
  //               }
  //               this.checkList = [];
  //               this.getCaptureList();
  //               this.isCheckedAll = false;
  //             }
  //           });
  //         })
  //         .catch(() => {
  //           this.$message({
  //             type: "info",
  //             message: "已取消删除"
  //           });
  //         });
  //     } else {
  //       this.$message({
  //         type: "info",
  //         message: "请先选中图片"
  //       });
  //     }
  //   },
  //   // 导出excel
  //   exportExcel() {
  //     if (this.checkList.length !== 0) {
  //       if (this.values.length == 0) {
  //         fetch(
  //           this.$axios.defaults.baseURL +
  //             `videoAlarmRecord/export?ids=${this.checkList}&siteName=${this.siteName}&startTime=&endTime=`,
  //           {
  //             headers: {
  //               token: localStorage.getItem("token")
  //             }
  //           }
  //         )
  //           .then(res => {
  //             return res.blob();
  //           })
  //           .catch(err => {
  //             console.log(err);
  //           })
  //           .then(e => {
  //             console.log(e);
  //             const a = document.createElement("a");
  //             a.href = URL.createObjectURL(e);
  //             a.download = "非法抓拍表.xlsx";
  //             a.click();
  //           });
  //       } else {
  //         fetch(
  //           this.$axios.defaults.baseURL +
  //             `videoAlarmRecord/export?ids=${this.checkList}&siteName=${this.siteName}&startTime=${this.startTime}&endTime=${this.endTime}`,
  //           {
  //             headers: {
  //               token: localStorage.getItem("token")
  //             }
  //           }
  //         )
  //           .then(res => {
  //             return res.blob();
  //           })
  //           .catch(err => {
  //             console.log(err);
  //           })
  //           .then(e => {
  //             console.log(e);
  //             const a = document.createElement("a");
  //             a.href = URL.createObjectURL(e);
  //             a.download = "非法抓拍表.xlsx";
  //             a.click();
  //           });
  //       }
  //     } else {
  //       this.$message({
  //         type: "info",
  //         message: "请先选中图片"
  //       });
  //     }
  //   },
  //   nextpage(val) {
  //     this.isCheckedAll = false;
  //     this.checkList = [];
  //     this.page = val;
  //     console.log(val);
  //     this.getCaptureList();

  //     // if (this.isCheckedAll) {
  //     //   // 全选时
  //     //   this.checkList = [];
  //     //   this.CaptureList.forEach(function(item) {
  //     //     this.checkList.push(item.id);
  //     //   }, this);
  //     // } else {
  //     //   this.checkList = [];
  //     // }
  //   },
  //   // 获取非法抓拍视频列表
  //   getCaptureList() {
  //     this.loading = true;
  //     this.$get(`/videoAlarmRecord/page/${this.page}/${this.size}`, {
  //       siteName: this.siteName,
  //       startTime: this.startTime,
  //       endTime: this.endTime
  //     }).then(res => {
  //       if (res.data.list.length == 0) {
  //         this.checkList = [];
  //         this.isCheckedAll = false;
  //       }
  //       this.total = res.data.total;
  //       this.CaptureList = res.data.list;
  //       for (const i of this.CaptureList) {
  //         this.CaptureListImg.push(i.alarmPicUrl);
  //       }
  //       this.pageSize = res.data.pageSize;
  //       this.loading = false;
  //     });
  //   },
  //   // 点击批量管理
  //   openShow() {
  //     this.show = !this.show;
  //   },
  //   // 查询的时候
  //   searchList() {
  //     this.loading = true;
  //     this.startTime = this.values[0];
  //     this.endTime = this.values[1];
  //     this.getCaptureList();
  //     this.loading = false;
  //   },
  //   // 重置
  //   resetList() {
  //     this.siteName = "";
  //     this.values = [];
  //     // this.values[0] = "";
  //     // this.values[1] = "";
  //   }
  // },
  created() {},
  mounted() {
    this.getCaptureList();
  }
};
</script>
<style lang="scss" scoped>
.capture_main {
  width: 100%;
  height: calc(822 / 1080 * 100vh);
  background: #ffffff;
  box-sizing: border-box;
  border-radius: calc(8 / 1920 * 100vw);
  .capture_main_box {
    height: calc(754 / 1080 * 100vh);
    display: flex;
    // justify-content: space-evenly;
    padding-top: calc(16 / 1080 * 100vh);
    flex-wrap: wrap;
    .capture_box {
      width: calc(501 / 1920 * 100vw);
      height: calc(358 / 1080 * 100vh);
      background: #6a6d72;
      margin-left: calc(16 / 1920 * 100vw);
      position: relative;
      .input-box {
        border: 1px solid #00b0ff;
        width: calc(31 / 1920 * 100vw);
        height: calc(31 / 1080 * 100vh);
        position: absolute;
        top: calc(8 / 1080 * 100vh);
        right: calc(15 / 1920 * 100vw);
      }
      img {
        width: 100%;
        height: calc(358 / 1080 * 100vh);
      }
    }
  }
}
.menu_right {
  justify-content: flex-end;
  .el-button {
    width: calc(86 / 1920 * 100vw);
  }
}
.checkedAll {
  cursor: pointer;
  margin-right: calc(39 / 1920 * 100vw);
  img {
    width: calc(24 / 1920 * 100vw);
    height: calc(24 / 1920 * 100vw);
    margin-right: calc(5 / 1920 * 100vw);
  }
  span {
    color: #626262;
  }
}
::v-deep .el-range-input {
  background: #f3f4f8 !important;
}
::v-deep .el-range-separator {
  background: #f3f4f8 !important;
}
::v-deep .el-date-editor.el-range-editor.el-input__inner {
  width: calc(265 / 1920 * 100vw) !important ;
}
::v-deep .el-image-viewer__close {
  width: 72px;
  height: 72px;
  > .el-icon-circle-close {
    font-size: 70px;
    &:before {
      content: "\e78d" !important;
    }
  }
}
</style>
